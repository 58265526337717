import heroimage from '../../assets/images/heroimage.png'
import atm from '../../assets/svg/atm.svg'
import basket from '../../assets/svg/basket.svg'
import blocks from '../../assets/svg/blocks.svg'
import cell from '../../assets/svg/cell.svg'
import globe from '../../assets/svg/globe.svg'
import light from '../../assets/svg/light.svg'
import ticket from '../../assets/svg/ticket.svg'
import tv from '../../assets/svg/tv.svg'
import tick from '../../assets/svg/tick.svg'
import blob from '../../assets/svg/blob.svg'
import exchange from '../../assets/images/exchange.png'
import leftarrow from '../../assets/svg/leftarrow.svg'
import easyfabric from '../../assets/images/easyfabric.png'
import easyfabric2 from '../../assets/images/easyfabric2.png'
import purchasepos from '../../assets/images/purchasepos.png'
import creditpass from '../../assets/svg/creditpass.svg'
import easyfabmain from '../../assets/images/easyfabmain.png'
import cashpoints from '../../assets/svg/cashpoints.svg'
import purchaseposg from '../../assets/svg/purchasepos.svg'
import purchaseblob from '../../assets/svg/purchaseblob.svg'


// eslint-disable-next-line
export default{
images:{
    heroimage,
    atm,
    basket,
    blocks,
    cell,
    globe,
    light,
    ticket,
    tv,
    tick,
    exchange,
    blob,
    leftarrow,
    easyfabric,
    easyfabric2,
    creditpass,
    cashpoints,
    purchasepos,
    purchaseposg,
    purchaseblob,
    easyfabmain
    
},
heroSection:{
    title: 'One Super App for All Your Payment and Financial Needs ',
    desc: 'Load up your MPayer Wallet with real money and make payments in a jiffy at a participating merchant stores, websites and apps.',
    action: 'Get the App',
    review: 'Best app I have ever used. I can easily make payment for ny stuffs in just a few clicks, great design!',
    vendor: 'Opemipo Oladiran Trader, Balogun Market. Lagos'
},
services:{ 
    title: 'Recharge, Order & Pay Bills',
    options:[
        {
            index: 1,
            title: 'Airtime Topup', 
            icon: cell,
        },
        {
            index: 2,
            title: 'Electricity Bill Payment',
            icon: light,
        },
        {
            index: 3,
            title: 'CableTV Subscription',
            icon: tv,
        },
        {
            index: 4,
            title: 'Internet Data Suscription',
            icon: globe,
        },
        {
            index: 5,
            title: 'Bus, Train & Flight Tickets',
            icon: ticket,
        },
        {
            index: 6,
            title: 'School Fees Payment',
            icon: atm,
        },
        {
            index: 7,
            title: 'Order Food & Groceries ',
            icon: basket,
        },
        {
            index: 8,
            title: 'All Payment Services',
            icon: blocks,
        },
    ]
},
makePayments:{
    title: 'The Best Way to Make and Receive Payments',
    desc: 'MPayer is a digital financial  services platform offering user easy, quick and secure access to important digital payment services like Funds Transfer, Bill Payment, Utility Subscriptions, Airtime Topup, Bulk Collections,',
    subtitle: 'Make payments in just seconds',
    subdesc: 'Make contactless  and secure QR Payments at a partner merchant outlet near you. Checkout in just a few seconds using your MPayer SwiftPay Wallet App.',
    items: [
        'Pay with your MPayer Wallet Credits',
        'Pay directly from your bank account',
        'Pay through direct wallet-wallet transfers',
    ],
    action: 'Find out how',
    path: '/merchants'
},
easyLife:{
    title: 'Experience the Easy Life',
    desc: "With your MPayer embedded lending features, your wishlist doesn't have to be on the waitlist. Shop for the things you want today and pay for them next month or two - groceries,  fashion, airtime and utility bills, as well  personal supplies  etc.",
    items: [
        {
            title: 'MPayer CreditPass',
            desc: 'With Mpayer Postpaid feature, you can still make payments even when your wallet balance is zero. Experience the easy life. spend now and pay us back later.',
            action: 'Learn More >',
            path: '/merchants',
            image: creditpass
        },
        {
            title: 'MPayer CashPoints',
            desc: 'Even with a zero wallet balance. You can still get the cash you need to meet up with important expenses and payments. Withdraw from any of our dedication merchant locations serving as cashout centers.',
            action: 'Learn More >',
            path: '/merchants',
            image: cashpoints
        },
    ]
},
purchases: {
    title: 'Purchases, Sales, Payments and Transfers',
    subtitle : 'Get amazing cashbacks when you transact',
    desc: 'MPayer is a digital financial  services platform offering user easy, quick and secure access to important digital services like Funds Transfer, Bill Payment, Utility Subscriptions, Airtime Topup, Bulk Collections,',
    action: 'Learn More >',
    path: '/merchants',
    item_title: 'Make QR Payments from Wallets ',
    item_desc: 'Make contactless  and secure QR Payments at a partner merchant outlets near you as well as online and checkout in just a few clicks using funds in your MPayer Wallet .',
    items: [
        'Scan a dynamic QR Code to make payment',
        'Pay with MPayer Wallet  Account Balance',
        'Pay with MPayer Wallet Credits'
    ],
    base_header: 'Pay with MPayer Wallet Credits',
    base_action: 'Sign up Now'
},

}
