import heroimage from '../../assets/images/heroimage.png'
import herocl from '../../assets/images/herocl.png'
import atm from '../../assets/svg/atm.svg'
import basket from '../../assets/svg/basket.svg'
import blocks from '../../assets/svg/blocks.svg'
import cell from '../../assets/svg/cell.svg'
import globe from '../../assets/svg/globe.svg'
import light from '../../assets/svg/light.svg'
import ticket from '../../assets/svg/ticket.svg'
import tv from '../../assets/svg/tv.svg'
import leftarrow from '../../assets/svg/leftarrow.svg'
import Sitting from '../../assets/images/Sitting.png'
import buynowimg from '../../assets/images/buynowimg.png'
import office from '../../assets/images/office.png'
import phone from '../../assets/images/phone.png'
import printing from '../../assets/images/printing.png'




// eslint-disable-next-line
export default{
images:{
    heroimage,
    atm,
    basket,
    blocks,
    cell,
    globe,
    light,
    ticket,
    tv,
    Sitting,
    leftarrow,
    buynowimg,
    office,
    phone,
    printing,
    herocl

},
heroSection:{
    title: 'The Easiest Way to Make Payments',
    desc: 'Buy airtime recharges & pay your bills, book flights & movie tickets, send & receive money, invest in stocks & mutual funds, and do a lot more.',
    action: 'Get the App'
},
services:{ 
    title: 'Recharge, Order & Pay Bills',
    options:[
        {
            index: 1,
            title: 'Airtime Topup', 
            icon: cell,
        },
        {
            index: 2,
            title: 'Electricity Bill Payment',
            icon: light,
        },
        {
            index: 3,
            title: 'CableTV Subscription',
            icon: tv,
        },
        {
            index: 4,
            title: 'Internet Data Suscription',
            icon: globe,
        },
        {
            index: 5,
            title: 'Bus, Train & Flight Tickets',
            icon: ticket,
        },
        {
            index: 6,
            title: 'School Fees Payment',
            icon: atm,
        },
        {
            index: 7,
            title: 'Order Food & Groceries ',
            icon: basket,
        },
        {
            index: 8,
            title: 'All Payment Services',
            icon: blocks,
        },
    ]
},
contactlessPayment:{
    title: 'A Unified Contactless Payment System',
    subtitle: 'The Fastest Way to Pay In-store & Online.',
    desc: 'Load up your MPayer Wallet for free and make payments in a jiffy at over 21 million stores, websites and apps.',
    action: 'Learn More',
    path: '/merchants',
    image: Sitting
},
buyNow:{
    title: 'MPayer Buy Now Pay Later',
    subtitle: 'Want it? No more waiting for it.',
    desc: "With MPayer CreditPass, your wishlist doesn't have to be on the waitlist. Shop for the things you want today and pay for them next month.",
    action: 'Learn More',
    path: '/merchants',
    image: buynowimg
},
securePayments:{
    title: 'Secure Payments Powered by MPayer',
    subtitle: 'Sell Online. Collect Payments Fast.',
    desc: 'MPayer payment service app offers secure, transparent and risk-free banking at your fingertips. With instant account opening, virtual debit card and zero balance requirements, experience the future of banking today.',
    action: 'Learn More',
    path: '/merchants',
    image: office
},
financialServices:{
    title: 'Financial Services Powered by MPayer',
    subtitle: 'Building Nigeria’s most secure and friendliest digital payment app.',
    desc: 'MPayer Payment Service App offers secure, transparent and risk-free banking at your fingertips. With instant account opening, virtual debit card and zero balance requirements, experience the future of payments-- today.',
    action: 'Learn More',
    path: '/merchants',
    image: phone
},
microLending:{
    title: 'Fast Micro-Lending Service on MPayer',
    subtitle: 'Get a Personal Loan in 2 Minutes.',
    desc: 'MPayer offers the quickest multi-purpose, hassle-free loan. It is 100% digital, transparent and paperless.',
    action: 'Learn More',
    path: '/merchants',
    image: printing
},
}