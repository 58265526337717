import React from 'react' 

const HeroSection = ({data, images }) => { 
  return (
    <div className=' '>
      {/* <div className='min-h-[8.1rem] shadow-inner shadow-[white]'></div> */}
      <div style={{ backgroundImage: `url(${images?.heroimage})`}} 
          className='overflow-x-hidden w-full min-h-[35rem] shadow-inner shadow-[white] text-center md:text-left px-[1.5rem] md:px-[3.9rem]  bg-no-repeat bg-cover bg-center '>  
          <h1 className='text-[#000000] text-[1.7rem] text-center md:text-left md:text-[3.4rem] font-[600] leading-[2.05rem] md:leading-[4.1rem] w-auto md:w-[31.2rem]'  >{data.title} </h1>
          <p className='text-[#000000] text-[1.25rem] text-center md:text-left leading-[1.9rem] font-[400] w-auto md:w-[35rem]  mt-[1.8em] ' >{data.desc} </p>
          <button className='mt-[7em] px-[0.9em] py-[0.4em] bg-[#00BAF2] text-[#FFFFFF] rounded-[0.6rem] font-[700] w-[14.5rem] h-[3.5rem] active:opacity-[0.5] ' >{data.action}</button>
      </div>
    </div>
  )
}

export default HeroSection