
import merchfabric from '../../assets/images/merchfabric.png';
import exchange from '../../assets/images/exchange.png';
import vendor from '../../assets/images/vendor.png';
import POS from '../../assets/images/POS.png';
import leftarrow from '../../assets/svg/leftarrow.svg';
import merchblob from '../../assets/svg/merchblob.svg'


// eslint-disable-next-line
export default{ 
images:{
    merchfabric,
    merchblob,
    leftarrow,
    exchange,
    POS,
    vendor
    
},
paymentNeeds:{
    title: 'One Solution for all your payment needs',
    desc: 'MPayer Business App gives merchants the freedom and choice to accept any form of payment in-person and online. Our simple app aggregates card schemes, buy now pay later, QR wallets. Accept payments globally Support hundreds of payment methods worldwide.',
    action: 'Sign Up Now',
    image: merchfabric
},
merchantsCard:{
    title: 'No more need for Expensive Hardware',
    desc: 'MPayer’s simple downloadable mobile POS solution gets you up and running in minutes. There’s no need for clunky and expensive hardware, complex integrations or costly maintenance. Simply download the application and onboard once for access to payment methods from around the world.',
    action: 'Sign Up Now',
    image: exchange
},
growBusiness:{
    title: 'Grow your business with MPayer',
    desc: 'Take payments from anywhere and start growing your business worldwide. Easily add payment methods your customers know and trust, and provide a frictionless checkout experience to increase conversion rates.',
    action: 'Sign Up Now',
    image: POS
},
acceptPayments:{
    title: 'Accept payments From multiple Channels',
    desc: 'Whether you want to accept payments online, in-store or both, MPayer offers an advanced solution for every type of merchant..',
    action: 'Sign Up Now',
    image: vendor
},

}