import React from "react";
import { Link } from "react-router-dom";
import Brand from "../../assets/images/Brand.png";
import Background from "../../assets/images/background.png";

const SignupView = () => {
  return (
    <div
      style={{ backgroundImage: `url(${Background})` }}
      className=" w-full min-h-[100vh] flex items-center pt-[3.8rem] p-[1.3rem] md:p-[3.8rem] bg-no-repeat bg-cover bg-center"
    >
      <div className="w-full md:w-[70%] lg:w-[39.8%] mx-auto">
        <Link to="/" className="flex justify-center">
          <img src={Brand} alt="brand logo" width={147} height={38.19} />
        </Link>
        <div className="border border-[#E0E0E0] border-solid mt-[1.8rem] py-[2.3rem] px-[1.9rem] md:py-[2.8rem] md:px-[3.9rem] ">
          <h2 className="text-[1.5rem] leading-[1.7rem] text-[#000000] font-[700] ">
            Nice to have you
          </h2>
          <p className="text-[0.9rem] leading-[1.7rem] text-[#000000] font-[400] ">
            Sign up to continue
          </p>
          {/*-------------- SIGN UP FORM ---------------------*/}
          <form action="" className="mt-[1.3rem] ">
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="text-[0.9rem] text-[#4F4F4F] leading-[1.7rem] font-[600]  "
              >
                First Name
              </label>
              <input
                type="text"
                className="border border-solid border-[#E0E0E0] rounded-[0.3rem] py-[0.16rem] px-[0.29rem] focus:outline-none "
              />
            </div>
            <div className="flex flex-col mt-[0.7rem]">
              <label
                htmlFor=""
                className="text-[0.9rem] text-[#4F4F4F] leading-[1.7rem] font-[600]"
              >
                Last Name
              </label>
              <input
                type="text"
                className="border border-solid border-[#E0E0E0] rounded-[0.3rem] py-[0.16rem] px-[0.29rem] focus:outline-none "
              />
            </div>
            <div className="flex flex-col mt-[0.7rem]">
              <label
                htmlFor=""
                className="text-[0.9rem] text-[#4F4F4F] leading-[1.7rem] font-[600]  "
              >
                Email
              </label>
              <input
                type="text"
                className="border border-solid border-[#E0E0E0] rounded-[0.3rem] py-[0.16rem] px-[0.29rem] focus:outline-none "
              />
            </div>
            <div className="flex flex-col mt-[0.7rem]">
              <label
                htmlFor=""
                className="text-[0.9rem] text-[#4F4F4F] leading-[1.7rem] font-[600]  "
              >
                Password
              </label>
              <input
                type="password"
                className="border border-solid border-[#E0E0E0] rounded-[0.3rem] py-[0.16rem] px-[0.29rem] focus:outline-none "
              />
            </div>
            <div className="flex flex-col mt-[2.5rem]">
              <button
                type="submit"
                className="bg-[#75BD38] rounded-[2px] text-[1rem] leading-[1.7rem] text-white font-[600] py-[0.8rem] px-[1.6rem] "
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-center">
          <p className="mt-[1.9rem] text-[0.9rem] leading-[1.7rem] text-[#656565] ">
            Already have an account, sign in{" "}
            <Link to="/login" className="text-[#3D3D3D] font-[700] underline ">
              here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupView;
